import _ from 'lodash'
import React, { useState } from 'react'
import Slider from 'react-slick'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { CardOutline } from '../../../../components'
import './our-approach-styles.scss'

const OurApproach = ({ t, data }) => {
  const [current, setCurrent] = useState(1)
  const breakpoints = useBreakpoint()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    afterChange: (index) => setCurrent(index + 1),
  }

  return (
    <div className="container-fluid">
      <div className="container-xxl ourApproach__container">
        <span className="ourApproach__tag">{t('home.approach.tag')}</span>
        <h2 className="ourApproach__title">{t('home.approach.title')}</h2>
        {breakpoints.sm ? (
          <>
            <Slider {...settings}>
              {_.map(data, (item, idx) => (
                <div className="col-3" key={idx} style={{ width: 300 }}>
                  <div style={{ padding: '0 10px' }}>
                    <CardOutline
                      withoutImg={false}
                      url={_.get(item, 'bg_image')}
                      title={_.get(item, 'title')}
                    >
                      <p className="cardOutline-text">
                        {_.get(item, 'description')}
                      </p>
                    </CardOutline>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="sliderMobile__footer">
              <span>
                {current}/{data.length}
              </span>
            </div>
          </>
        ) : (
          <div className="row g-4">
            {_.map(data, (item, idx) => (
              <div className="col-3 ourApproach__card" key={idx}>
                <CardOutline
                  withoutImg={data.length !== idx + 1}
                  isLastImage={data.length === idx + 1}
                  url={data.length === idx + 1 ? _.get(item, 'bg_image') : null}
                  title={_.get(item, 'title')}
                >
                  <p className="cardOutline-text">
                    {_.get(item, 'description')}
                  </p>
                </CardOutline>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default OurApproach
