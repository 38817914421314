import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import HomeView from './home-view'

const HomeController = () => {
  const [page, setPage] = useState([])
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  const getHome = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'page',
      params: {
        lang: i18n.language,
        slug: 'home',
      },
    }).then((resp) => {
      setPage(resp.data)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getHome()
  }, [i18n.language])

  const viewProps = {
    loading,
    data: page,
    t,
  }
  return <HomeView {...viewProps} />
}

export default HomeController
