import _ from 'lodash'
import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import './hero-image-styles.scss'
import { Button } from '../../../../components'
import getUrlMedia from '../../../../helpers/get-url-media'

const HeroImage = ({ t, data }) => {
  const hasHighlight = _.map(
    _.get(data, 'todays_highlights'),
    (item) => item !== null,
  ).some((e) => e === true)
  const heroContainer = classNames({
    'container-xxl': true,
    heroImage__container: true,
    hasHighlight,
  })
  return (
    <div className="container-fluid">
      <div className={heroContainer}>
        <div className="row heroImage__block__up">
          <div className="col-12 col-sm-5 heroImage__content">
            <h1 className="heroImage__title">
              {_.head(_.get(data, 'banner_section')).title}
            </h1>
            {
              process.env.GATSBY_HAS_QUOTE === 'true' && (
                <Button
                  big
                  link="/quotation/"
                  text={t('home.heroImage.buttonText')}
                />
              )
            }
          </div>
          <div className="col-12 col-sm-7">
            <div className="appendix">
              <div className="heroImage__image">
                <img src={getUrlMedia(_.head(_.get(data, 'banner_section')).image)} alt="" />
              </div>
            </div>
          </div>
        </div>
        {hasHighlight && (
          <div className="row">
            <div className="col-12 heroImage__highlights">
              <h5>{t('home.highlights')}</h5>
              {_.map(
                _.get(data, 'todays_highlights'),
                (item, idx) => item && (
                  <Link to={`/news/${_.get(item, 'slug')}/`}>
                    <div key={idx}>
                      <p>{_.get(item, 'cta_text')}</p>
                    </div>
                  </Link>
                ),
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HeroImage
